body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #3D4648; /* Dark background */
    color: #f3f4f6; /* Light text color */
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: auto;
    min-height: 100vh;
    overflow: auto;
}

root{
    overflow: auto;
}


.auth-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.auth-box {
    background-color: #2B3031; /* Slightly lighter dark background for the box */
    border-radius: 0.5rem;
    padding: 2rem;
    width: 400px;
    max-width: 400px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    text-align: center; /* Center text within the auth box */
}

.logo {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    color: #22A66E; /* Bright green for branding */
}

.subtitle {
    text-align: center; /* Ensure subtitle is centered */
    color: #9ca3af; /* Subtitle color */
    margin-bottom: 2rem;
}

.btn {
    display: block;
    width: calc(100% - 2rem); /* Full width with padding consideration */
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 0.5rem auto; /* Space between buttons, centered */
}

.btn-primary {
    background-color: #22A66E; /* Green color from your CSS */
    color: white;
  }

.btn-primary:hover {
    background-color: #1c865a; /* Darker shade for hover */
  }

.btn-google {
    background-color: #22A66E; /* Bright green button color */
    color: white;
}

.btn-google:hover {
    background-color: #1c865a; /* Darker shade for hover */
}

.btn-email {
    background-color: #374151; /* Button color */
    color: white;
}

.btn-email:hover {
    background-color: #4b5563; /* Hover color */
}

.info-box {
    background-color: #2c2c2c; /* Info box color */
    border-radius: 0.375rem;
    padding: 1rem;
    margin-top: 1.5rem; /* Reduced margin */
    font-size: 0.875rem;
    color: #93c5fd; /* Info text color */
    text-align: center; /* Center text */
}

.input-field {
    width: calc(100% - 2rem); /* Full width with padding consideration */
    padding: 0.75rem;
    border: 1px solid #374151; /* Dark border */
    border-radius: 0.375rem;
    background-color: #2c2c2c; /* Dark background for input */
    color: #f3f4f6; /* Light text color */
    margin-bottom: 1rem; /* Space between fields */
    transition: border-color 0.2s;
}

.input-field:focus {
    border-color: #22A66E; /* Focus border color */
    outline: none; /* Remove default outline */
}

/* Navbar styles 
.navbar {
    background-color: #2B3031;
    padding: 16px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
*/

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.navbar-title {
    color: #22A66E;
    font-weight: bold;
    margin: 0;
}

.navbar-links a {
    color: #f3f4f6;
    margin-right: 16px;
    text-decoration: none;
}

.navbar-button {
    background-color: #22A66E;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
}

.navbar-button:hover {
    background-color: #1c865a;
}


.text-center {
    text-align: center; /* Center text for any element with this class */
}

.google-logo {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    margin-right: 8px; /* Space between logo and text */
    vertical-align: middle; /* Align logo with text */
}

/* Google Sign-In Button Styles */
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

/* Pricing Page Styles */
.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem; /* Increased top padding to account for navbar */
}

.pricing-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.pricing-subtitle {
  font-size: 1rem;
  text-align: center;
  color: #9ca3af;
  margin-bottom: 2rem;
}

.pricing-plans {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allow wrapping */
}

.pricing-plan {
  background-color: #2B3031;
  border-radius: 0.5rem;
  padding: 2rem;
  width: 300px;
  text-align: center;
  margin-bottom: 2rem; /* Add space between plans when in column */
}

.plan-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.plan-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 1rem;
  background-color: #22A66E; /* Primary button color */
  color: white;
}

.plan-button:hover {
  background-color: #1c865a; /* Primary button hover color */
}

.plan-features {
  text-align: left;
  list-style-type: none;
  padding: 0;
}

.plan-features li {
  margin-bottom: 0.5rem;
}

.plan-features li::before {
  content: "✔";
  color: #22A66E;
  margin-right: 0.5rem;
}

/* Media query for smaller screens */
@media (max-width: 1024px) {
  .pricing-plans {
    flex-direction: column;
    align-items: center;
  }

  .pricing-plan {
    width: 100%;
    max-width: 300px; /* Limit width on very wide screens */
  }
}

/* Add these styles at the end of your existing CSS */

.player-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3D4648; /* Updated to match the body background color */
}

.player-wrapper {
  transform-origin: center;
}
