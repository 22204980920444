@font-face {
  font-family: 'Komika Axis';
  src: url('./fonts/KOMIKAX_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Theboldfont';
  src: url('./fonts/theboldfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
